import React from 'react';
import ReactDOM from 'react-dom/client';

// import './assets/css/index.css';
// import './assets/css/bootstrap.min.css';

import { createBrowserRouter,RouterProvider } from "react-router-dom"
import Auth from './components/Auth';
import App from './components/App';
import Url from './components/Url';
import MyUrl from './components/MyUrl';
import Products from './components/Products';
import ProductDetail from './components/ProductDetail';
import ProductDetailOwn from './components/ProductDetailOwn';
import Fields from './components/Fields';
import FieldsOwn from './components/FieldsOwn';
import ProductsTable from './components/ProductsTable';
import ProductsTableOwn from './components/ProductsTableOwn';

import reportWebVitals from './reportWebVitals';

import Cookies from 'universal-cookie';

// SET COOKIES
const cookies = new Cookies();

// END SET COOKIES
document.body.classList.add('nav-fixed');

const router = createBrowserRouter([
  // {path:"/",element:<Auth />,errorElement: <h1>404</h1>},
  {path:"/",element:<Auth />},
  {path:"/about",element:<h1>About</h1>},

  {path:"/admin/fields",element:<Fields />},
  {path:"/admin/fields/own",element:<FieldsOwn />},

  {path:"/productos",element:<App />},
  {path:"/products/view/:idProduct",element:<ProductDetail />},
  {path:"/products/own/view/:idProduct",element:<ProductDetailOwn />},

  {path:"/products",element:<Products />},
  {path:"/products/:page",element:<Products />},
  {path:"/products/url/:idUrl",element:<Products />},
  {path:"/products/url/:idUrl/:page",element:<Products />},


  {path:"/products/table",element:<ProductsTable />},
  {path:"/products/table/:page",element:<ProductsTable />},
  {path:"/products/table/url/:idUrl",element:<ProductsTable />},
  {path:"/products/table/url/:idUrl/:page",element:<ProductsTable />},
  
  {path:"/products/own/table",element:<ProductsTableOwn />},

  {path:"/urls",element:<Url />},
  {path:"/urls/:page",element:<Url />},
  {path:"/urls/own",element:<MyUrl />}

]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
