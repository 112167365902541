import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getProducts, getProductsByIdURL, getFindProduct, getFields } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function ProductsTable() {

  const parameterPage = useParams();
  const [respCall,setResponse] = useState(true);
  const [respCallFields,setResponseFields] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []

  const refreshData = () => 
  {

    const refreshConstFields = async () => {
      setLoading1(true);
      setResponseFields(await getFields(cookies.get('public_token')))
      setLoading1(false);
    };
    if (searchQuery==null) {
      if (parameterPage.idUrl==undefined)
      {
        const refreshConst = async () => {
          setLoading2(true);
          setResponse(await getProducts(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page)))
          setLoading2(false);
        };
        
        refreshConst();
      }
      else
      {
        const refreshConst = async () => {
          setLoading2(true);
          setResponse(await getProductsByIdURL(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page),parameterPage.idUrl))
          setLoading2(false);
        };
        
        refreshConst();
      }
    }
    else
    {
      const refreshConst = async () => {
        setLoading2(true);
        setResponse(await getFindProduct(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page),searchQuery))
        setLoading2(false);
      };
      refreshConst();
    }
      
    refreshConstFields();

  };

  useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
        </div>
    </>
    );
  };

  const ShowData = () => {

    var objAuxiliar = {};
    respCallFields.result.map((r) => {
      if (r.active && typeof fieldsHeader[r.field] !== undefined)
      {
        objAuxiliar[r.field] = {'field':r.field,'name':r.name,'active':r.active,'showTable':r.show_table};
      }
    });

    fieldsHeader.push(objAuxiliar);

    return (
      <>
        <div class="table-responsive">
          <table className="table table-borderless mb-0" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info">
            <thead className="border-bottom">
              <tr role="row">
                {Object.keys(respCall.result[0]).map((key) => {
                  return (
                    <>
                      {fieldsHeader[0].hasOwnProperty(key) == true && fieldsHeader[0][key]['active'] == 1 && fieldsHeader[0][key]['showTable'] == 1 &&
                        <th>{fieldsHeader[0][key]['name']}</th>
                      }
                    </>
                  );
                })}
                <th>Actions</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                {Object.keys(respCall.result[0]).map((key) => {
                  return (
                    <>
                      {fieldsHeader[0].hasOwnProperty(key) == true && fieldsHeader[0][key]['active'] == 1 && fieldsHeader[0][key]['showTable'] == 1 &&
                        <th>{fieldsHeader[0][key]['name']}</th>
                      }
                    </>
                  );
                })}
                <th>Actions</th>
              </tr>
            </tfoot>
            <tbody>
            {respCall.result.map((r) => {
              return (
                <>
                  <tr className="border-bottom">
                    {Object.keys(r).map(key => {
                      return (
                        <>
                          {fieldsHeader[0].hasOwnProperty(key) == true && fieldsHeader[0][key]['active'] == 1 && fieldsHeader[0][key]['showTable'] == 1 &&
                              <td>{r[key]}</td>
                          }
                        </>
                      );
                    })}
                    <td>
                      <Link to={`/products/view/${r.id_product_available}`} className="btn btn-primary btn-sm">
                        View detail
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ShowPagination = (parameters) => {

    const pageNumbers = [];

    for (let i = 1; i <= parameters.totalPages; i++){
      pageNumbers.push(i);
    }
 
    return (
      <>
        <ul className="pagination">
          <li className="paginate_button page-item previous disabled" id="dataTable_previous">
            <a href="#" aria-controls="dataTable" data-dt-idx="0" className="page-link">Previous</a>
          </li>
          {pageNumbers.map((number) => {
            return (
              <>
                  <li className="paginate_button page-item ${number == parameters.currentPage ? 'active':''}" key="pagination_{number}">

                    <Link to={parameterPage.idUrl==undefined?`/products/table/${number}`:`/products/table/url/${parameterPage.idUrl}/${number}`} onClick={() => refreshData(number)} className="page-link" key="pag{number}">{number}</Link>
                  </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Blank Starter
                                    </h1>
                                    <div className="page-header-subtitle">Use this blank page as a starting point for creating new pages inside your project!</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Optional page header content</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">Product List</h6>
                    </div>
                    <div class="card-body">
                      {loading1 || loading2 ? <Loading /> : <ShowData /> }
                      {loading1 || loading2 ? '' : <ShowPagination totalPages={respCall.total_page} currentPage={respCall.current_page} /> }
                    </div>
                  </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default ProductsTable;
