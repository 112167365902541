import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getFieldsOwn, putMap } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function FieldsOwn() {

  const parameterPage = useParams();
  const [respCall,setResponse] = useState(true);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")

  const handleSubmit = async (e) => {

    e.preventDefault();

    const data = new FormData(e.target);

    const jsonForm = Object.fromEntries(data.entries());

    // const navigate = useNavigate();

    const elements = e.target.elements;

    console.log(JSON.stringify(jsonForm));

    await putMap(cookies.get('public_token'),JSON.stringify(jsonForm));

  };

  const refreshData = () => 
  {

    const refreshConst = async () => {
      setLoading(true);
      setResponse(await getFieldsOwn(cookies.get('public_token')))
      setLoading(false);
    };
    refreshConst();
  };

  useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
      <div class="row">
        <div className="col-md-12">
            <Skeleton height={100}/>
        </div>
        <div className="col-md-12">
            <Skeleton height={100}/>
        </div>
        <div className="col-md-12">
            <Skeleton height={100}/>
        </div>
        <div className="col-md-12">
            <Skeleton height={100}/>
        </div>
      </div>
    </>
    );
  };

  const ShowData = () => {
    return (
      <>
      <form onSubmit={handleSubmit} method="POST">
        {respCall.result.map((r) => {
            if (r.blocked==0)
            {
              return (
              <>
                <div className="row">
                  <div className="mb-3 col-md-8">
                    <label className="small mb-1" htmlFor="inputUsername">{r.field}</label>
                    <input className="form-control" type="text" placeholder="Enter your username" name={`${r.field}`} id={`${r.field}`} defaultValue={`${r.name}`}/>
                  </div>
                  <div className="mb-3 col-md-2">
                    <div className="">
                      <label className="form-check-label" htmlFor={`status_${r.field}`}>
                        Status
                      </label>
                      <br />
                      <input className="form-check-input" type="checkbox" defaultChecked={(r.active==1)?`checked`:``} id={`status_${r.field}`} name={`status_${r.field}`} />
                    </div>
                  </div>
                  <div className="mb-3 col-md-2">
                    <div className="">
                      <label className="form-check-label" htmlFor={`table_${r.field}`}>
                        Show in Table
                      </label>
                      <br />
                      <input className="form-check-input" type="checkbox" defaultChecked={(r.show_table==1)?`checked`:``} id={`table_${r.field}`} name={`table_${r.field}`} />
                    </div>
                  </div>
                </div>
              </>
              );
            }
          })}
        <button class="btn btn-primary btn-block">UPDATE</button>
      </form>
      </>
    );
  };

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Fields Maping
                                    </h1>
                                    <div className="page-header-subtitle">Use this blank page as a starting point for creating new pages inside your project!</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Optional page header content</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  <div className="container-xl px-4 mt-n10">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                          <h6 className="m-0 font-weight-bold text-primary">Fields Maping</h6>
                      </div>
                      <div className="card-body">
                        {loading ? <Loading /> : <ShowData />}
                      </div>
                    </div>
                  </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default FieldsOwn;
