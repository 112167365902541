import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Navbar = () => 
{
	return (
		<div id="layoutSidenav_nav">
		    <nav className="sidenav shadow-right sidenav-light">
		        <div className="sidenav-menu">
		            <div className="nav accordion" id="accordionSidenav">
		                <div className="sidenav-menu-heading d-sm-none">Account</div>
		                <a className="nav-link d-sm-none" href="#!">
		                    <div className="nav-link-icon"><i data-feather="bell"></i></div>
		                    Alerts
		                    <span className="badge bg-warning-soft text-warning ms-auto">4 New!</span>
		                </a>
		                <a className="nav-link d-sm-none" href="#!">
		                    <div className="nav-link-icon"><i data-feather="mail"></i></div>
		                    Messages
		                    <span className="badge bg-success-soft text-success ms-auto">2 New!</span>
		                </a>
		                <div className="sidenav-menu-heading">Core</div>
		                <a className="nav-link collapsed" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
		                    <div className="nav-link-icon"><i data-feather="activity"></i></div>
		                    Dashboards
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </a>
		                <div className="collapse" id="collapseDashboards" data-bs-parent="#accordionSidenav">
		                    <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
		                        <a className="nav-link" href="dashboard-1.html">
		                            Default
		                            <span className="badge bg-primary-soft text-primary ms-auto">Updated</span>
		                        </a>
		                        <a className="nav-link" href="dashboard-2.html">Multipurpose</a>
		                        <a className="nav-link" href="dashboard-3.html">Affiliate</a>
		                    </nav>
		                </div>
		                <div className="sidenav-menu-heading">SCAN</div>
		                <Link className="nav-link collapsed" to="/urls" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Websites to scan
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/products" data-bs-toggle="collapse" data-bs-target="#collapseApps" aria-expanded="false" aria-controls="collapseApps">
		                    <div className="nav-link-icon"><i data-feather="globe"></i></div>
		                    Products Grid
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>

		                <Link className="nav-link collapsed" to="/products/table" data-bs-toggle="collapse" data-bs-target="#collapseFlows" aria-expanded="false" aria-controls="collapseFlows">
		                    <div className="nav-link-icon"><i data-feather="repeat"></i></div>
		                    Products Table
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/admin/fields" data-bs-toggle="collapse" data-bs-target="#collapseFlows" aria-expanded="false" aria-controls="collapseFlows">
		                    <div className="nav-link-icon"><i data-feather="repeat"></i></div>
		                    Fields Maping
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">MY DATA</div>
		                <Link className="nav-link collapsed" to="/urls/own" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    My Websites
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/products/own/table" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    My Products
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/admin/fields/own" data-bs-toggle="collapse" data-bs-target="#collapseFlows" aria-expanded="false" aria-controls="collapseFlows">
		                    <div className="nav-link-icon"><i data-feather="repeat"></i></div>
		                    My Fields Maping
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                
		            </div>
		        </div>
		        <div className="sidenav-footer">
		            <div className="sidenav-footer-content">
		                <div className="sidenav-footer-subtitle">Logged in as:</div>
		                <div className="sidenav-footer-title">{cookies.get('username')}</div>
		            </div>
		        </div>
		    </nav>
		</div>
	)
}
export default Navbar;