import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getProducts, getProductsByIdURL, getFindProduct } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Products() {
  
  document.body.classList.add('nav-fixed');

  const parameterPage = useParams();
  const [respCall,setResponse] = useState(true);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")

  const refreshData = () => 
  {

    if (searchQuery==null) {
      if (parameterPage.idUrl==undefined)
      {
        const refreshConst = async () => {
          setLoading(true);
          setResponse(await getProducts(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page)))
          setLoading(false);
        };
        refreshConst();
      }
      else
      {
        const refreshConst = async () => {
          setLoading(true);
          setResponse(await getProductsByIdURL(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page),parameterPage.idUrl))
          setLoading(false);
        };
        refreshConst();
      }
    }
    else
    {
      const refreshConst = async () => {
        setLoading(true);
        setResponse(await getFindProduct(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page),searchQuery))
        setLoading(false);
      };
      refreshConst();
    }

  };

  useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
          <div className="col-md-3 mb-4">
              <Skeleton height={350}/>
              <Skeleton count={3}/>
          </div>
        </div>
    </>
    );
  };

  const ShowData = () => {
    return (
      <>
        <div className="row">
          {respCall.result.map((r) => {
            return (
              <>
                <div className="col-md-3 mb-4" key="data_{r.id_url}">
                  <div className="card h-100 text-center">
                    <div className="card-body">
                      <img src="https://via.placeholder.com/300x300" className="img-fluid mb-2"/>
                      <h5 className="card-title mb-0">{r.field1}</h5>
                      <p>$ {r.normal_price}</p>
                      <p>$ {r.discount_price}</p>
                      <p className="card-text lead fw-bold">
                        {r.reference_1.substring(0,10)}
                      </p>
                      <Link to={`/products/view/${r.id_product_available}`} className="btn btn-primary btn-sm">
                        View detail
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };
  const ShowPagination = (parameters) => {

    const pageNumbers = [];

    for (let i = 1; i <= parameters.totalPages; i++){
      pageNumbers.push(i);
    }
 
    return (
      <>
        <ul className="pagination">
          <li className="paginate_button page-item previous disabled" id="dataTable_previous">
            <a href="#" aria-controls="dataTable" data-dt-idx="0" className="page-link">Previous</a>
          </li>
          {pageNumbers.map((number) => {
            return (
              <>
                  <li className="paginate_button page-item ${number == parameters.currentPage ? 'active':''}" key="pagination_{number}">

                    <Link to={parameterPage.idUrl==undefined?`/products/${number}`:`/products/url/${parameterPage.idUrl}/${number}`} onClick={() => refreshData(number)} className="page-link" key="pag{number}">{number}</Link>
                  </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Products grid view
                                    </h1>
                                    <div className="page-header-subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Mini Lipsum</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">Product List</h6>
                    </div>
                    <div class="card-body">
                      {loading ? <Loading /> : <ShowData />}
                      <ShowPagination totalPages={respCall.total_page} currentPage={respCall.current_page} />
                    </div>
                  </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default Products;
