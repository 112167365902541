import React, { useState, useEffect } from "react";
import {Link,useParams} from "react-router-dom";
import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

export function plus(a, b)
{
  return a + b;
}
export function getApi(endpoint,token)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'GET',
            headers: { 'Authorization-Prod': token}
        };
    const response = await fetch("https://scraping.digitag.cl/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function putApi(endpoint,token,data)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'PUT',
            body: data,
            headers: { 'Authorization-Prod': token}
        };
    const response = await fetch("https://scraping.digitag.cl/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function postApi(endpoint,token,data)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Authorization-Prod': token}
        };
    const response = await fetch("https://scraping.digitag.cl/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function putMap(accessToken,data)
{
  return putApi("admin/fields/update/"+accessToken,accessToken,data);
}
export function postMyURL(accessToken,data)
{
  return postApi("admin/own/url/"+accessToken,accessToken,data);
}
export function Authentication(user,password)
{
  return getApi("login/user/"+user+"/"+password,null);
}
export function getURL(accessToken,page=1)
{
  return getApi("urls/all/"+accessToken+"/"+(page==undefined?1:page),accessToken);
}
export function getMyURL(accessToken,page=1)
{
  return getApi("urls/own/"+accessToken+"/"+(page==undefined?1:page),accessToken);
}
export function getFindURL(accessToken,page=1,$find)
{
  return getApi("urls/find/"+accessToken+"/"+(page==undefined?1:page)+"/"+$find,accessToken);
}
export function getProducts(accessToken,page=1)
{
  return getApi("products/all/"+accessToken+"/"+(page==undefined?1:page),accessToken);
}
export function getProductsByIdURL(accessToken,idUrl,page=1)
{
  return getApi("products/all/"+accessToken+"/"+(page==undefined?1:page)+"/"+idUrl,accessToken);
}
export function getFindProduct(accessToken,page=1,$find)
{
  return getApi("products/find/"+accessToken+"/"+(page==undefined?1:page)+"/"+$find,accessToken);
}

export function getProductsOwner(accessToken,page=1)
{
  return getApi("products/own/all/"+accessToken+"/"+(page==undefined?1:page),accessToken);
}


export function getProductDetail(accessToken,$find)
{
  return getApi("products/detail/"+accessToken+"/"+$find,accessToken);
}
export function getFields(accessToken)
{
  const response = getApi("admin/fields/"+accessToken,accessToken);
  return response
}
export function getFieldsOwn(accessToken)
{
  const response = getApi("admin/fields/own/"+accessToken,accessToken);
  return response
}
// export function baseTPL(showData,Pagination,totalPages,currentPage)
// {
//   return (
//     <>
//       <div id="wrapper">
//         <Navbar />
//         <div id="content-wrapper" className="d-flex flex-column">
//             <div id="content">
//               <Header />

//               <div className="container-fluid">
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                   <h1 className="h3 mb-0 text-gray-800">Products</h1>
//                 </div>
//                 <div className="row">
//                   <ShowData />
//                 </div>
//               </div>
//               <ShowPagination totalPages={totalPages} currentPage={currentPage} />
//               <Footer />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// const ShowPagination = (parameters) => {

//   const pageNumbers = [];

//   for (let i = 1; i <= parameters.totalPages; i++){
//     pageNumbers.push(i);
//   }

//   return (
//     <>
//       <ul className="pagination">
//         <li className="paginate_button page-item previous disabled" id="dataTable_previous">
//           <a href="#" aria-controls="dataTable" data-dt-idx="0" className="page-link">Previous</a>
//         </li>
//         {pageNumbers.map((number) => {
//           return (
//             <>
//                 <li className="paginate_button page-item ${number == parameters.currentPage ? 'active':''}" key="pagination_{number}">

//                   <Link to={parameterPage.idUrl==undefined?`/products/${number}`:`/products/url/${parameterPage.idUrl}/${number}`} onClick={() => refreshData(number)} className="page-link" key="pag{number}">{number}</Link>
//                 </li>
//             </>
//           );
//         })}
//       </ul>
//     </>
//   );
// };