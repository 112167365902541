import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getMyURL, getFindURL, postMyURL } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function MyUrl() {

  const parameterPage = useParams();
  const [respCall,setResponse] = useState(true);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")

  const handleSubmit = async (e) => {

    e.preventDefault();

    const data = new FormData(e.target);

    const jsonForm = Object.fromEntries(data.entries());

    const elements = e.target.elements;

    console.log(JSON.stringify(jsonForm));

    await postMyURL(cookies.get('public_token'),JSON.stringify(jsonForm));

  };
  
  const refreshData = () => 
  {
    if (searchQuery==null) {
      const getProducts = async () => {
        setLoading(true);
        setResponse(await getMyURL(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page)))
        setLoading(false);
      };
      getProducts();

    }
    else
    {
      const getProducts = async () => {
        setLoading(true);
        setResponse(await getFindURL(cookies.get('public_token'),(parameterPage.page==undefined?1:parameterPage.page),searchQuery))
        setLoading(false);
      };
      getProducts();
    }
  }

  useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
        </div>
    </>
    );
  };

  const ShowData = () => {
    return (
      <>
      {respCall.total_result==0?(
        <div>
          <form onSubmit={handleSubmit} method="POST">
            <div className="mb-3">
                <label className="small mb-1" htmlFor="url">URL (Insert URL of your website)</label>
                <input className="form-control" id="url" type="text" placeholder="Add your website here: https://demo.com" name="url" />
            </div>
            <div className="mb-3">
                <label className="small mb-1" htmlFor="urlFeed">URL Feed</label>
                <input className="form-control" id="urlFeed" type="text" placeholder="Add your feed URL here" name="url_feed" />
            </div>
            <div className="mb-3">
              <button className="btn btn-primary" type="submit">Add your first Website</button>
            </div>
          </form>
        </div>
      ):(
        <table className="table table-borderless mb-0">
            <thead className="border-bottom">
                <tr className="small text-uppercase text-muted">
                    <th scope="col">URL</th>
                    <th className="text-end" scope="col">Products</th>
                    <th className="text-end" scope="col">Active</th>
                    <th className="text-end" scope="col">Date Add</th>
                    <th className="text-end" scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {respCall.result.map((r) => {
                  return (
                    <>
                      <tr className="border-bottom">
                          <td>
                              <div className="fw-bold">{r.url.substring(0,100)}</div>
                              {r.url_feed}
                          </td>
                          <td className="text-end fw-bold">
                            {r.total_urls}
                          </td>
                          <td className="text-end fw-bold">{r.active==1?<span className="badge bg-primary">Yes</span>:<span className="badge bg-light text-body">No</span>}</td>
                          <td className="text-end fw-bold">{r.date_add}</td>
                          <td className="text-end fw-bold">
                            <Link to={`/products/url/${r.id_url}`} className="btn btn-primary btn-sm mx-1 hidden">
                              <div className="nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg></div>
                              &nbsp;&nbsp;URL
                            </Link>
                            <Link to={`/products/own/table/`} className="btn btn-primary btn-sm mx-1">
                              <div className="nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg></div>
                              &nbsp;&nbsp;PRODUCTS
                            </Link>
                          </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
        </table>
      )}

      </>
    );
  };
  const ShowPagination = (parameters) => {

    const pageNumbers = [];

    for (let i = 1; i <= parameters.totalPages; i++){
      pageNumbers.push(i);
    }
 
    return (
      <>
        <ul className="pagination">
          <li className="paginate_button page-item previous disabled" id="dataTable_previous">
            <a href="#" aria-controls="dataTable" data-dt-idx="0" className="page-link">Previous</a>
          </li>
          {pageNumbers.map((number) => {
            return (
              <>
                  <li className="paginate_button page-item ${number == parameters.currentPage ? 'active':''}" key="pagination_{number}">

                    <Link to={`/urls/${number}`} onClick={() => refreshData(number)} className="page-link" key="pag{number}">{number}</Link>
                  </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        My Websites
                                    </h1>
                                    <div className="page-header-subtitle">Use this blank page as a starting point for creating new pages inside your project!</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Optional page header content</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card border-left-success shadow h-100">
                        <div className="card-header">List Our Websites</div>
                        <div className="card-body">
                          <div className="row">
                            {loading ? <Loading /> : <ShowData />}
                            {loading ? '' : respCall.total_result==0?'':<ShowPagination totalPages={respCall.total_page} currentPage={respCall.current_page} /> }
                          </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default MyUrl;
