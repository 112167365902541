import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getProductDetail, getFieldsOwn } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function ProductDetailOwner() {

  const parameterPage = useParams();
  const [respCall,setResponse] = useState(true);
  const [respCallFields,setResponseFields] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []

  const refreshData = () => 
  {

    const refreshConstFields = async () => {
      setLoading1(true);
      setResponseFields(await getFieldsOwn(cookies.get('public_token')))
      setLoading1(false);
    };

    const refreshConst = async () => {
      setLoading2(true);
      setResponse(await getProductDetail(cookies.get('public_token'),parameterPage.idProduct))
      setLoading2(false);
    };

    refreshConstFields();
    refreshConst();
  };

  useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
      <div className="row">
          <div className="col-md-4">
            <div className="card mb-4 mb-xl-0">
                <Skeleton height={400}/>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card mb-8 mb-xl-0">
                <Skeleton height={80}/>
                <Skeleton count={30}/>
            </div>
          </div>
      </div>
    </>
    );
  };
  // if (loading) {
  //   return <h1> Data is loading...</h1>
  // }
  function contains(a, obj) {
      for (var i = 0; i < a.length; i++) {
          if (a[i] === obj) {
              return true;
          }
      }
      return false;
  }

  const ShowData = () => {


    var objAuxiliar = {};
    respCallFields.result.map((r) => {
      if (r.active && typeof fieldsHeader[r.field] !== undefined)
      {
        objAuxiliar[r.field] = {'field':r.field,'name':r.name,'active':r.active,'showTable':r.show_table};
      }
    });

    fieldsHeader.push(objAuxiliar);

    return (
      <>
        <div className="row">
        {respCall.result.map((r) => {
          return (
            <>
            <div className="col-xl-4">
              <div className="row">
                <div className="col-xl-12 col-md-12 mb-4">
                  <div className="card mb-4 mb-xl-0">
                      <div className="card-header">Thumb Image</div>
                      <div className="card-body text-center">
                        <img className="img-account-profile mb-2 img-fluid" src={`${r.url_image}`} alt="" />
                      </div>
                  </div>
                </div>
                <hr />
                <div className="col-xl-6 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Normal Price</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{r.normal_price}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 mb-4">
                  <div className="card border-left-warning h-100">
                      <div className="card-body">
                          <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                      Offer Price</div>
                                  <div className="h5 mb-0 font-weight-bold text-gray-800">{r.discount_price}</div>
                              </div>
                              <div className="col-auto">
                                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <hr />
                <div className="col-xl-6 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Date Add</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{r.date_creation}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Last Update</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{r.last_update}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">

              <div className="card border-left-success shadow h-100">
                  <div className="card-header">{r.field1}</div>
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">{r.field1}</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">&nbsp;</div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                        
                    </div>
                    <div className="row">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(r).map(key => {
                            return (
                              <>
                                {fieldsHeader[0].hasOwnProperty(key) == true && fieldsHeader[0][key]['active'] == 1 &&
                                  <tr>
                                    <td>{fieldsHeader[0][key]['name']}</td>
                                    <td>{r[key]}</td>
                                  </tr>
                                }
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
            </div>
            </>
            );
        })}
        </div>
      </>
    );
  };


  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Blank Starter
                                    </h1>
                                    <div className="page-header-subtitle">Use this blank page as a starting point for creating new pages inside your project!</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Optional page header content</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  {loading1 || loading2 ? <Loading/> : <ShowData /> }
                  
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default ProductDetailOwner;
