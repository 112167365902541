import React from "react";
import {Link} from "react-router-dom";
		            // <input type="text" classNameName="form-control bg-light border-0 small" placeholder="Search for..."

const Header = () => {
	return (
		<nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
		    <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><i data-feather="menu"></i></button>
		    <a className="navbar-brand pe-3 ps-4 ps-lg-2" href="index.html">SCRAP<font>LY</font> <sup>1.2</sup></a>
		    <form className="form-inline me-auto d-none d-lg-block me-3">
		        <div className="input-group input-group-joined input-group-solid">
		            <input className="form-control pe-0" type="search" placeholder="Search" aria-label="Search" name="search"/>
		            <div className="input-group-text"><i data-feather="search"></i></div>
		        </div>
		    </form>
		    <ul className="navbar-nav align-items-center ms-auto hide hidden o-hidden">
		        <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
		            <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="img-fluid" src="assets/img/illustrations/profiles/profile-1.png" /></a>
		            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
		                <h6 className="dropdown-header d-flex align-items-center">
		                    <img className="dropdown-user-img" src="assets/img/illustrations/profiles/profile-1.png" />
		                    <div className="dropdown-user-details">
		                        <div className="dropdown-user-details-name">Valerie Luna</div>
		                        <div className="dropdown-user-details-email">vluna@aol.com</div>
		                    </div>
		                </h6>
		                <div className="dropdown-divider"></div>
		                <a className="dropdown-item" href="#!">
		                    <div className="dropdown-item-icon"><i data-feather="settings"></i></div>
		                    Account
		                </a>
		                <a className="dropdown-item" href="#!">
		                    <div className="dropdown-item-icon"><i data-feather="log-out"></i></div>
		                    Logout
		                </a>
		            </div>
		        </li>
		    </ul>
		</nav>
	);
}
export default Header;